import React from 'react';
import Experience from './Experience';
import Education from './Education';
import Skills from './Skills';


class ExperienceAndEducation extends React.Component {
    render() {
        return (
            <div className="experience-and-education">
                <div className="column-wide">
                    <Experience />
                </div>

                <div className="column-narrow">
                    <Skills />
                    <Education />
                </div>

            </div>
        );
    }
}

export default ExperienceAndEducation;