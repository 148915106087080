import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { fetchWorksData } from '../../actions';
import WorksNavigation from './WorksNavigation';
import DevicesNavigation from './DevicesNavigation';
import PageCounter from './PageCounter';
import PageContainer from './PageContainer';
import PageNavigation from './PageNavigation';


class Works extends React.Component {

    componentDidMount() {
        this.props.fetchWorksData();
    }

    getPagePath(worksList, selectedWork, selectedPage) {
        const selectedPageInt = parseInt(selectedPage);

        return worksList[selectedWork] ?
            worksList[selectedWork].path + worksList[selectedWork].pages[selectedPageInt - 1]
            : '';
    }

    getNumberOfAllPages(worksList, selectedWork) {
        return worksList[selectedWork] ?
            worksList[selectedWork].pages.length
            : 0;
    }

    getWorkName(worksList, selectedWork) {
        return worksList[selectedWork] ?
            worksList[selectedWork].name
            : '';
    }

    render() {
        const { worksList, isLoading, hasErrored } = this.props.works;

        const urlParams = this.props.match.params;
        const { selectedDevice, selectedWork, selectedPage } = urlParams;

        if (isLoading) {
            return <p>Loading...</p>
        }
        
        if (hasErrored) {
            return <p>Sorry! There was an error loading the works. Please try again later.</p>
        }

        if (!isLoading && !hasErrored) {
            const pagePath = this.getPagePath(worksList, selectedWork, selectedPage);
            const numberOfAllPages = this.getNumberOfAllPages(worksList, selectedWork);
            const workName = this.getWorkName(worksList, selectedWork);

            return (
                <div className="works">
                    <h1>Works</h1>
                    <WorksNavigation
                        urlParams={urlParams}
                        worksList={worksList}
                    />
                    <div className={`device ${selectedDevice}`}>
                    <h2>{workName}</h2>
                        <PageNavigation
                            urlParams={urlParams}
                            numberOfAllPages={numberOfAllPages}
                        />
                        <PageCounter
                            numberOfAllPages={numberOfAllPages}
                            selectedPage={selectedPage}
                        />
                        <DevicesNavigation
                            urlParams={urlParams}
                        />
                        <PageContainer
                            pagePath={pagePath}
                        />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToPros = (state) => {
    return { 
        works: state.works
    };
}

export default withRouter(connect(mapStateToPros, { fetchWorksData })(Works));
