export const SHOW_MENU = 'SHOW_MENU';
export const HIDE_MENU = 'HIDE_MENU';
export const FETCH_EXPERIENCE_DATA = 'FETCH_EXPERIENCE_DATA';
export const FETCH_EXPERIENCE_SUCCESS = 'FETCH_EXPERIENCE_SUCCESS';
export const FETCH_EXPERIENCE_FAILURE = 'FETCH_EXPERIENCE_FAILURE';
export const FETCH_EDUCATION_DATA = 'FETCH_EDUCATION_DATA';
export const FETCH_EDUCATION_SUCCESS = 'FETCH_EDUCATION_SUCCESS';
export const FETCH_EDUCATION_FAILURE = 'FETCH_EDUCATION_FAILURE';
export const FETCH_SKILLS_DATA = 'FETCH_SKILLS_DATA';
export const FETCH_SKILLS_SUCCESS = 'FETCH_SKILLS_SUCCESS';
export const FETCH_SKILLS_FAILURE = 'FETCH_SKILLS_FAILURE';
export const FETCH_EDUCATION = 'FETCH_EDUCATION';
export const FETCH_SKILLS = 'FETCH_SKILLS';
export const FETCH_WORKS_DATA = 'FETCH_WORKS_DATA';
export const FETCH_WORKS_SUCCESS = 'FETCH_WORKS_SUCCESS';
export const FETCH_WORKS_FAILURE = 'FETCH_WORKS_FAILURE';
