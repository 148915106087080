import { combineReducers } from 'redux';
import menuReducer from './menuReducer';
import experienceReducer from './experienceReducer';
import educationReducer from './educationReducer';
import skillsReducer from './skillsReducer';
import worksReducer from './worksReducer';


export default combineReducers({
    menu: menuReducer,
    experience: experienceReducer,
    education: educationReducer,
    skills: skillsReducer,
    works: worksReducer,
});
