import _ from 'lodash';
import {
    FETCH_WORKS_DATA,
    FETCH_WORKS_SUCCESS,
    FETCH_WORKS_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    worksList: {},
    isLoading: true,
    hasErrored: false,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_WORKS_DATA:
            return { ...state,
                isLoading: true,
                hasErrored: false,
            };
        case FETCH_WORKS_SUCCESS:
            return { ...state, 
                worksList: _.mapKeys(action.payload.worksList, 'id'),
                isLoading: false,
                hasErrored: false,
            };
        case FETCH_WORKS_FAILURE:
            return { ...state,         
                isLoading: false,
                hasErrored: true, 
            };
        default:
            return state;
    };
};