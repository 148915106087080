import React from 'react';

class PageContainer extends React.Component {
    render() {
        const { pagePath } = this.props;

        return (
            <div className="page-container">
                <iframe key={pagePath} src={pagePath} title="page" className="page"></iframe>
            </div>
        );
    }
}

export default PageContainer;