import React from 'react';
import { Link } from 'react-router-dom';

class DevicesNavigation extends React.Component {

    render() {
        const { selectedDevice, selectedWork, selectedPage } = this.props.urlParams;

        return (
            <ul className="device-type">
                <li className={selectedDevice === 'desktop' ? 'active' : ''}>
                    <Link to={`/works/desktop/${selectedWork}/${selectedPage}`}>Desktop</Link>
                </li>
                <li className={selectedDevice === 'tablet' ? 'active' : ''}>
                    <Link to={`/works/tablet/${selectedWork}/${selectedPage}`}>Tablet</Link>
                </li>
                <li className={selectedDevice === 'mobile' ? 'active' : ''}>
                    <Link to={`/works/mobile/${selectedWork}/${selectedPage}`}>Mobile</Link>
                </li>
            </ul>
        );
    }
}

export default DevicesNavigation;
