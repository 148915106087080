import React from 'react';
import { Link } from 'react-router-dom';

class WorksNavigation extends React.Component {

    renderList(normalizedWorksList, urlParams) {
        const { selectedDevice, selectedWork } = urlParams;

        return normalizedWorksList.map(work => {
            return (
                <li key={work.id} className={work.id === selectedWork ? 'active' : ''}>
                    <Link to={`/works/${selectedDevice}/${work.id}/1`}>{work.name}</Link>
                </li>
            );
        });
    }

    render() {
        const { worksList, urlParams } = this.props;

        const normalizedWorksList = Object.values(worksList);

        return (
            <ul className="works-list">
                {this.renderList(normalizedWorksList, urlParams)}
            </ul>
        );
    }
}

export default WorksNavigation;
