import {
    FETCH_EDUCATION_DATA,
    FETCH_EDUCATION_SUCCESS,
    FETCH_EDUCATION_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    educationList: {},
    isLoading: true,
    hasErrored: false,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_EDUCATION_DATA:
            return { ...state,
                isLoading: true,
                hasErrored: false,
            };
        case FETCH_EDUCATION_SUCCESS:
            return { ...state, 
                educationList: action.payload,
                isLoading: false,
                hasErrored: false,
            };
        case FETCH_EDUCATION_FAILURE:
            return { ...state,         
                isLoading: false,
                hasErrored: true, 
            };
        default:
            return state;
    };
};