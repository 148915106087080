import React from 'react';

const Hello = () => {
    return (
        <div className="hello">
            <h1>Hello</h1>
            <p>
                I am Grzegorz.<br />
                I’m a frontend developer<br />
                based in Gdańsk, Poland.
            </p>
            <a href="mailto:2gr@2gr.pl" className="button">Contact me</a>
        </div>
    );
}

export default Hello;