import React from 'react';
import { Link, withRouter } from 'react-router-dom'

class Footer extends React.Component {
    render() {
        return (
            <footer className={this.props.location.pathname === '/' ? 'fixed' : ''}>
                <div className="content">
                <Link to="/" className="logo" title="2gr.pl - Homepage"><img src="img/logo-2gr.png" alt="2gr.pl - Homepage" /></Link>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/experience-and-education">Experience &amp; Education</Link></li>
                        <li><Link to="/works/desktop/eteatr/1">Works</Link></li>
                        <li><a href="mailto:2gr@2gr.pl">Contact</a></li>
                    </ul>
                    <p>Hand coded by 2gr with help from React &amp; Redux. All rights reserved.</p>
                </div>
            </footer>
        );
    };
}

export default withRouter(Footer);