import {
    SHOW_MENU,
    HIDE_MENU,
    FETCH_EXPERIENCE_DATA,
    FETCH_EXPERIENCE_SUCCESS,
    FETCH_EXPERIENCE_FAILURE,
    FETCH_EDUCATION_DATA,
    FETCH_EDUCATION_SUCCESS,
    FETCH_EDUCATION_FAILURE,
    FETCH_SKILLS_DATA,
    FETCH_SKILLS_SUCCESS,
    FETCH_SKILLS_FAILURE,
    FETCH_WORKS_DATA,
    FETCH_WORKS_SUCCESS,
    FETCH_WORKS_FAILURE
} from './types';
import json from '../api/json';

export const showMenu = () => {
    return { type: SHOW_MENU };
}

export const hideMenu = () => {
    return { type: HIDE_MENU };
}

export const fetchEducationData = () => async dispatch => {
    dispatch({      
        type: FETCH_EDUCATION_DATA    
    });

    try {   
        const response = await json.get('education.json');  
        dispatch({
            type: FETCH_EDUCATION_SUCCESS,        
            payload: response.data      
        });    
    } catch (err) {          
        dispatch({        
            type: FETCH_EDUCATION_FAILURE  
        });    
    }  
};

export const fetchSkillsData = () => async dispatch => {
    dispatch({      
        type: FETCH_SKILLS_DATA    
    });

    try {   
        const response = await json.get('skills.json');  
        dispatch({
            type: FETCH_SKILLS_SUCCESS,        
            payload: response.data      
        });    
    } catch (err) {          
        dispatch({        
            type: FETCH_SKILLS_FAILURE  
        });    
    }  
};

export const fetchExperienceData = () => async dispatch => {
    dispatch({      
        type: FETCH_EXPERIENCE_DATA    
    });

    try {   
        const response = await json.get('experience.json');  
        dispatch({
            type: FETCH_EXPERIENCE_SUCCESS,        
            payload: response.data      
        });    
    } catch (err) {          
        dispatch({        
            type: FETCH_EXPERIENCE_FAILURE  
        });    
    }  
};

export const fetchWorksData = () => async dispatch => {
    dispatch({      
        type: FETCH_WORKS_DATA    
    });
    try {
        const response = await json.get('works.json');
        dispatch({
            type: FETCH_WORKS_SUCCESS,        
            payload: { worksList: response.data } 
        });    
    } catch (err) {         
        dispatch({        
            type: FETCH_WORKS_FAILURE  
        });    
    }  
};
