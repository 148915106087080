import React from 'react';
import { connect } from 'react-redux';
import { fetchEducationData } from '../../actions';

class Education extends React.Component {
    componentDidMount() {
        this.props.fetchEducationData();
    }

    renderSchoolList() {
        return this.props.education.educationList.map(school => {
            return (
                <div className="company" key={school.id}>
                    <h3>{school.date}</h3>
                    <h4>{school.name}</h4>
                    <h5>{school.field}</h5>
                </div>
            );
        });
    };

    render() {
        const { isLoading, hasErrored } = this.props.education;

        if (isLoading) {
            return <p>Loading...</p>
        }
        
        if (hasErrored) {
            return <p>Sorry! There was an error loading the education. Please try again later.</p>
        }
        return (
            <>
                <h2>Education</h2>
                {this.renderSchoolList()}
            </>
        );
    }
}

const mapStateToPros = (state) => {
    return { 
        education: state.education
    };
}

export default connect(mapStateToPros, { fetchEducationData })(Education);
