import React from 'react';
import  { Link } from 'react-router-dom';

class PageNavigation extends React.Component {

    render() {
        const { numberOfAllPages } = this.props;
        const { selectedDevice, selectedWork, selectedPage } = this.props.urlParams;
        
        const selectedPageInt = parseInt(selectedPage);

        const previousPage = selectedPageInt > 1 ? selectedPageInt - 1 : numberOfAllPages;
        const nextPage = selectedPageInt < numberOfAllPages ? selectedPageInt + 1 : 1;

        return (
            <>
                <div className="pager">
                    <Link to={`/works/${selectedDevice}/${selectedWork}/${previousPage}`} className="previous" />
                    <Link to={`/works/${selectedDevice}/${selectedWork}/${nextPage}`} className="next" />
                </div>
            </>
        );
    }
}

export default PageNavigation;
