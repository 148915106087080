import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div className="page-404">
            <h1>Page not found...</h1>
            <p><Link to='/'>Go back to Homepage.</Link></p>
        </div>
    );
}

export default PageNotFound;
