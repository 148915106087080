import React from 'react';

class PageCounter extends React.Component {
    render() {
        let { selectedPage, numberOfAllPages } = this.props;
        
        if (numberOfAllPages === 0) {
            selectedPage = 0;
        }

        return (
            <span className="counter">
                <strong>{selectedPage}</strong> / <span>{numberOfAllPages}</span>
            </span>
        );
    }
}

export default PageCounter;