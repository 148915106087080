import React from 'react';
import { connect } from 'react-redux';
import { fetchExperienceData } from '../../actions';

class Experience extends React.Component {
    componentDidMount() {
        this.props.fetchExperienceData();
    }

    renderStackList(stack) {
        return stack.map((item, index) => {
            return (
                <li key={index}>{item}</li>
            );
        });
    };

    renderCompanyList() {
        return this.props.experience.experienceList.map(company => {
            return (
                <div className="company" key={company.id}>
                    <h3>{company.date}</h3>
                    <h4>{company.name}</h4>
                    <h5>{company.position}</h5>
                    <ul>
                        {this.renderStackList(company.stack)}
                    </ul>
                </div>
            );
        });
    };

    render() {
        const { isLoading, hasErrored } = this.props.experience;

        if (isLoading) {
            return <p>Loading...</p>
        }
        
        if (hasErrored) {
            return <p>Sorry! There was an error loading the experience. Please try again later.</p>
        }
        return (
            <>
                <h2>Experience</h2>
                {this.renderCompanyList()}
            </>
        );
    }
}

const mapStateToPros = (state) => {
    return { 
        experience: state.experience
    };
}

export default connect(mapStateToPros, { fetchExperienceData })(Experience);
