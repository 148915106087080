import React from 'react';
import  { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { showMenu, hideMenu } from '../../actions';

class Header extends React.Component {

    toggleMenu = event => {
        event.stopPropagation();
        
        if (this.props.isMenuShowed) {
            this.props.hideMenu();
        } else {
            this.props.showMenu();
        }
    }

    hideMenu = event => {
        event.stopPropagation();

        if (this.props.isMenuShowed) {
            this.props.hideMenu();
        }
    }

    render() {
        const activeClassName = this.props.isMenuShowed ? 'active' : '';

        return (
            <header className={this.props.location.pathname.includes('/works') ? 'static' : ''}>
                <div className="content">
                    <Link to="/" className="logo" title="2gr.pl - Homepage">
                        <img src="img/logo-2gr.png" alt="2gr.pl - Homepage" />
                    </Link>
                    <div className={activeClassName}>
                        <div className="hamburger" onClick={this.toggleMenu}>
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </div>
                        <ul onClick={this.hideMenu}>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/experience-and-education">Experience &amp; Education</Link></li>
                            <li><Link to="/works/desktop/eteatr/1">Works</Link></li>
                            <li><a href="mailto:2gr@2gr.pl">Contact</a></li>
                        </ul>
                        <div className="glass" onClick={this.toggleMenu}></div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMenuShowed: state.menu.isMenuShowed
    };
}

export default withRouter(connect(mapStateToProps, { showMenu, hideMenu })(Header));
