import {
    FETCH_SKILLS_DATA,
    FETCH_SKILLS_SUCCESS,
    FETCH_SKILLS_FAILURE
} from '../actions/types';

const INITIAL_STATE = {
    skillsList: {},
    isLoading: true,
    hasErrored: false,
}

export default (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_SKILLS_DATA:
            return { ...state,
                isLoading: true,
                hasErrored: false,
            };
        case FETCH_SKILLS_SUCCESS:
            return { ...state, 
                skillsList: action.payload,
                isLoading: false,
                hasErrored: false,
            };
        case FETCH_SKILLS_FAILURE:
            return { ...state,         
                isLoading: false,
                hasErrored: true, 
            };
        default:
            return state;
    };
};