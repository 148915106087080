import { SHOW_MENU, HIDE_MENU } from '../actions/types';

const INITIAL_STATE = {
    isMenuShowed: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_MENU:
            return { ...state, 
                isMenuShowed: true
            };
        case HIDE_MENU:
            return { ...state,
                isMenuShowed: false
            };
        default: 
            return state;
    }
}