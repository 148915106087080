import React from 'react';
import { connect } from 'react-redux';
import { fetchSkillsData } from '../../actions';

class Skills extends React.Component {
    componentDidMount() {
        this.props.fetchSkillsData();
    }

    renderSkillsList() {
        return this.props.skills.skillsList.map(skill => {
            return (
                <li key={skill.id}><div style={{width: skill.level}}>{skill.skill}</div></li>
            );
        });
    };

    render() {
        const { isLoading, hasErrored } = this.props.skills;

        if (isLoading) {
            return <p>Loading...</p>
        }
        
        if (hasErrored) {
            return <p>Sorry! There was an error loading the skills. Please try again later.</p>
        }
        return (
            <>
                <h2>Skills</h2>
                <div className="skills">
                    <ul>
                        {this.renderSkillsList()}
                    </ul>
                </div>
            </>
        );
    }
}

const mapStateToPros = (state) => {
    return { 
        skills: state.skills
    };
}

export default connect(mapStateToPros, { fetchSkillsData })(Skills);
