import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import '../styles/styles.scss';
import Header from './common/Header';
import Footer from './common/Footer';
import PageNotFound from './common/PageNotFound';

import Hello from './hello/';
import ExperienceAndEducation from './experienceAndEducation/';
import Works from './works/';


const App = () => {
    return (
        <Router>
            <Header />
            <main>
                <Switch>
                    <Route path="/" exact component={Hello} />
                    <Route path="/experience-and-education" exact component={ExperienceAndEducation} />
                    <Route path="/works/:selectedDevice/:selectedWork/:selectedPage" exact component={Works} />
                    <Route component={PageNotFound} />
                </Switch>
            </main>
            <Footer />
        </Router>
    );
}

export default App;
