import axios from 'axios';

const prod = {
    url: 'http://2gr.pl/json/'
};
const dev = {
    url: 'http://localhost:3000/json/'
};

const baseURL = process.env.NODE_ENV === 'development' ? dev.url : prod.url;

export default axios.create({
    baseURL: baseURL
});